.home-page{
.ad{
    width: 100%;
    height: 100px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
}
.home-content{
    margin: 30px 0;
    display: flex;
    .headlines{
        width: 23%;
    }
}

}