@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --primary: #bf2f2b;
  --border:#d5d5d5;
  --section-bg: #00000008;
}
.large-font{
  font-size: 20px;
}
.title-font{
  font-size: 18px;
}

.main-container{
  padding: 30px 40px;
}
.container{
  padding: 10px 40px;
}
.padding-r-l{
  padding-left: 45px;
  padding-right: 45px;
}